import { messages } from "../i18n/message"
import { useAppStore } from "../store"
import type { PageContext } from "./types"
export function onBeforeRoute(pageContext: PageContext) {
  if (pageContext.urlOriginal) {
    const { locale, urlWithoutLocale } = extractLocale(pageContext.urlOriginal)
    return {
      pageContext: {
        redirectTo: '/login',
        locale,
        urlOriginal: urlWithoutLocale
      }
    }
  }
}

function extractLocale(url: string) {
  const langMap = Object.keys(messages) as Array<keyof typeof messages>
  let locale: keyof typeof messages = 'tc'
  let urlWithoutLocale = url
  langMap.some(lang => {
    if (url.includes(`/${lang}/`)) {
      locale = lang
      urlWithoutLocale = url.replace(`/${lang}`, '')
    }
    return url.includes(`/${lang}/`)
  })

  return {
    locale,
    urlWithoutLocale
  }
}